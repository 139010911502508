<template>
    <form-field>
        <fieldset>
            <legend
                v-if="props.label"
                class="form-label"
            >
                {{ props.label }}
                <span
                    v-if="props.required"
                    class="text-red-600"
                >*</span>
            </legend>

            <ul>
                <li
                    v-for="value in props.values"
                    :key="value.id"
                >
                    <div class="form-checkbox">
                        <input
                            :id="`${props.id}-${value.id ?? value.value}`"
                            v-model="modelValue"
                            type="checkbox"
                            :value="value.value"
                            :name="props.name"
                        />

                        <label
                            :for="`${props.id}-${value.id ?? value.value}`"
                            class="form-label"
                        >{{ value.label }}</label>
                    </div>
                </li>
            </ul>
        </fieldset>

        <form-field-errors :errors="errors" />
    </form-field>
</template>

<script setup lang="ts">
    export interface CheckboxItem {
        id: string;
        label: string;
        value: any;
    }

    export interface Props {
        id: string;
        name?: string;
        label?: string;
        values: CheckboxItem[];
        required?: boolean;
        errors?: string[] | string;
    }

    const props = withDefaults(defineProps<Props>(), {
        name: undefined,
        label: undefined,
        required: false,
        errors: undefined,
    });

    const modelValue = defineModel<Array<string | number>>();
</script>
